/*
 * @Description: 登录
 * @Autor: CYF
 * @Date: 2022-03-14 10:36:15
 * @LastEditors: CYF
 * @LastEditTime: 2023-10-08 13:54:00
 */
import ajax from '../ajax';

// 获取验证码
export const getVerificationCode = (data: { key: any }) => ajax('/v2/www/login/sendVcode', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 校验验证码
export const checkVerificationCode = (data: { key: any, vode: string }) => ajax('/v2/www/login/checkVcode', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 获取公钥
export const getPublicKey = (data: { uuid: any }) => ajax('/v2/www/login/getPublicKey', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 登录
export const login = (data?: {
  username?: string;
  password?: string;
  userType?: 1 | 2; // 1: 个人 2: 单位
}) => ajax('/v2/www/login/password', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 后台登录
export const adminLogin = (data?: {
  username: string;
  password: string;
}) => ajax('/v3/admin/login/password', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 获取邮箱验证码
export const getEmailVcode = (data: { email: string }) => ajax('/v2/www/login/sendEmailCode', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 校验邮箱验证码
export const checkEmail = (data: { email: string; code: string }) => ajax('/v2/www/login/checkEmailCode', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 重置密码-邮箱
export const resetPasswordByEmail = (data?: {
  password: string;
  email: string;
  code: string;
}) => ajax('/v2/www/login/modifyPasswordByEmail', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 重置密码-旧密码（登录状态）
export const resetPasswordByOld = (data?: {
  newPassword: string;
  oldPassword: string;
}) => ajax('/v2/www/personal/uc/modifyPasswordByOldPwd', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 重置密码（登录状态）
export const resetPassword = (data: {
  password: string;
}) => ajax('/v2/www/personal/uc/modifyPassword', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 重置密码-旧密码-后台（登录状态）
export const resetAdminPassWordByOld = (data?: {
  newPassword: string;
  oldPassword: string;
}) => ajax('/v3/admin/login/modifyPasswordByOldPwd', data, 'POST_FORM');

// 重置密码-后台（登录状态）
export const resetAdminPassword = (data: {
  password: string;
}) => ajax('/v3/admin/uc/modifyPassword', data, 'POST_FORM', { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

// 退出登录
export const signOut = (data: {}) => ajax('', data, 'POST');

// 服务平台单点登录
export const loginCas = (data: {}) => ajax('/v2/www/login/cas', data, 'POST_FORM');
