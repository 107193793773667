/*
 * @Date: 2022-03-09 15:52:46
 * @LastEditors: shencl
 * @LastEditTime: 2022-03-16 09:17:49
 * @Description: 功能描述
 * @FilePath: \training-platform\src\store\modal\myExamStore.ts
 */
import { makeAutoObservable, action, runInAction } from 'mobx';
import {
  getExamList, getExamInfo, getTrainInfo, getExamHistory, getWorkType, getExamTypeStatus, getExamInfoTypeStatus,
} from '@/api/server/myExam';

class MyExamStore {
  // 当前所在分页
 page:number=1;

 // 当前分页容量
pageSize:number=6

//  内容总数
ptotal:number=0

// 当前数据
examList:any[]=[]

// 课程考试列表
examInfoList:any[]=[]

//  培训数据
trainInfo:any={}

// 考试历史记录
historydata:any[]=[]

// 课程考试总数
trainExamTotal:number=0

//  试卷页数
testPage:number=0

// 工作类型列表
workTypeList=[]

// 考试进度
examProgress=[]

// 历史数据当前页
examHistoryPageNum:number=0;

// 历史考试记录总数
examHistoryTotal:number=0

// 历史考试数据页容量
examHistoryPageSize:number=0

// 加载动画触发
loading:boolean=false

// 已取证数量
certifiedNum:number=0

// 培训结束数量
endNum:number=0

// 总数
totalNum:number=0

// 培训中数量
trainingNum:number=0

// 已通过
passedNum:number=0

// 待考试
pendingExamNum:number=0;

// 考试总数
totalExamInfoNum:number=0

// 获取培训考试列表
  @action getExamList = async (data: any) => {
    this.loading = true;
    const res: any = await getExamList(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.page = res.result.page;
        this.examList = res.result.data;
        this.ptotal = res.result.total;
        this.loading = false;
      }
    });
    return res;
  }

  // 用于获取tab栏请求参数
  @action getExamValue = async (data: any) => {
    const res: any = await getExamInfo(data);
    return res;
  }

  // 获取课程考试列表
  @action getExamInfo = async (data: any) => {
    const res: any = await getExamInfo(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.testPage = res.result.page;
        this.examInfoList = res.result.data;
        this.trainExamTotal = res.result.total;
      }
    });
    return res;
  }

  // 获取培训基本数据
  @action getTrainInfo = async (data: any) => {
    const res: any = await getTrainInfo(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.trainInfo = res.result;
        this.examProgress = res.result.testedProgress.split('/');
      }
    });
    return res;
  }

  // 获取考试历史数据
  @action getExamHistory = async (data: any) => {
    const res: any = await getExamHistory(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.examHistoryPageNum = res.result.page;
        this.examHistoryPageSize = res.result.pages;
        this.examHistoryTotal = res.result.total;
        this.historydata = res.result.data;
      }
    });
    return res;
  }

  // 获取工作类型
 @action getWorkType = async () => {
   const res: any = await getWorkType();
   if (res.state.value === 0) {
     const temp = res.result.map((item:any) => ({
       label: item.workTypeName,
       value: item.workTypeCode,
     }));
     runInAction(() => {
       this.workTypeList = temp ?? [];
     });
   }
   return this.workTypeList;
 }

 //  获取培训进度数量
 @action getExamStatus = async (data: any) => {
   const res: any = await getExamList(data);
   runInAction(() => {

   });
   return res;
 }

 // 获取考试栏切换数据
  @action getExamTypeStatus = async (data: any) => {
    const res: any = await getExamTypeStatus(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.certifiedNum = res.result.certifiedNum;
        this.endNum = res.result.endNum;
        this.totalNum = res.result.totalNum;
        this.trainingNum = res.result.trainingNum;
      }
    });
    return res;
  }

  // 获取考试详情栏切换数据
  @action getExamInfoTypeStatus = async (data: any) => {
    const res: any = await getExamInfoTypeStatus(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.passedNum = res.result.passedNum;
        this.pendingExamNum = res.result.pendingExamNum;
        this.totalExamInfoNum = res.result.totalNum;
      }
    });
    return res;
  }

  constructor() {
    makeAutoObservable(this);
  }
}
export default new MyExamStore();
