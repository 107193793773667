/* eslint-disable max-len */

/**
 * 学习图标
 */
const StudySvg = ({ style } : { style?: any }) => (
  <span style={style}>
    <svg viewBox="0 0 1024 1024" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2794" width="1em" height="1em">
      <path d="M878.43 273.46l-339.5-94.08c-17.43-5.99-36.47-5.98-53.86 0l-337.53 93.46-1.96 0.63c-20.01 7.18-32.93 25.55-32.93 46.8 0 21.26 12.93 39.64 32.93 46.81l339.5 94.07c8.7 2.99 17.81 4.49 26.93 4.49 9.11 0 18.22-1.5 26.93-4.49l305.8-84.66v173.48c0 15.26 12.37 27.63 27.63 27.63S900 565.23 900 549.97V351.86c7.15-8.66 11.37-19.63 11.37-31.6-0.01-21.25-12.94-39.62-32.94-46.8z" p-id="2795" />
      <path d="M805.99 507.2c0-36.57-34.88-63.06-70.1-53.24l-79.75 22.23 0.04 0.02L529.4 511.1a65.9 65.9 0 0 1-34.81 0.04l-129-35.21-79.64-22.2c-35.23-9.82-70.1 16.67-70.1 53.24v200.06c0 37.27 24.87 69.95 60.78 79.9l208.43 57.71c8.71 3 17.82 4.49 26.94 4.49 9.11 0 18.23-1.49 26.94-4.49l206.32-57.11c35.78-9.92 60.77-42.78 60.77-79.9V546.14c0-0.35-0.04-0.69-0.05-1.03V507.2z" p-id="2796" />
    </svg>
  </span>
);

/**
 * 铃铛图标
 */
const BellSvg = ({ style } : { style?: any }) => (
  <span style={style}>
    <svg viewBox="0 0 1025 1024" fill="currentColor" width="1em" height="1em">
      <path d="M1024 804.544c0 19.776-7.808 36.928-23.424 51.392-15.616 14.464-34.048 21.696-55.36 21.696l-275.712 0c0 40.384-15.36 74.88-46.144 103.424C592.576 1009.728 555.456 1024 512 1024s-80.576-14.272-111.36-42.88c-30.784-28.544-46.144-63.04-46.144-103.424L78.784 877.696c-21.312 0-39.808-7.232-55.36-21.696C7.808 841.536 0 824.384 0 804.544c20.544-16 39.232-32.768 56-50.304 16.832-17.536 34.24-40.32 52.288-68.288 18.048-27.968 33.344-58.176 45.824-90.56C166.656 563.008 176.896 523.776 184.96 477.696c8.064-46.08 12.032-95.616 11.968-148.544 0-57.856 24-111.68 72-161.408 48-49.728 110.976-79.936 188.864-90.56C454.528 69.952 452.928 62.528 452.928 54.848c0-15.232 5.76-28.16 17.28-38.848S495.616 0 512 0c16.384 0 30.336 5.312 41.856 16 11.52 10.688 17.28 23.616 17.28 38.848 0 7.616-1.6 15.04-4.864 22.336 77.952 10.688 140.864 40.832 188.864 90.56 48 49.664 72 103.488 72 161.408 0 52.928 3.968 102.464 11.968 148.544 8 46.08 18.24 85.312 30.784 117.696 12.544 32.384 27.84 62.592 45.824 90.56 17.984 27.968 35.456 50.752 52.288 68.288C984.832 771.776 1003.52 788.608 1024 804.544L1024 804.544z" p-id="11132" />
    </svg>
  </span>
);

/**
 * 账号管理图标
 */
const ManageSvg = ({ style } : { style?: any }) => (
  <span style={style}>
    <svg viewBox="0 0 1024 1024" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11981" width="1em" height="1em">
      <path fill="currentColor" d="M375.7056 581.0688V446.208L492.1344 378.88l113.408 64.3072c3.072 3.072 6.144 6.144 6.144 9.216v128.7168l-119.552 67.3792-116.4288-67.3792z m585.2672 131.7376v-395.264c0-42.9056-21.4016-79.7184-61.2864-101.12L553.472 17.2544a119.04 119.04 0 0 0-119.5008 0l-346.2656 196.096C50.8928 234.8032 26.368 274.6368 26.368 314.4704v395.264c0 42.9056 21.4528 79.7184 61.2864 101.1712l349.3376 196.096a119.04 119.04 0 0 0 119.5008 0l349.3376-196.096c33.7408-18.432 55.1424-58.2656 55.1424-98.0992z m-257.3824-79.6672L495.2064 752.64l-208.384-119.5008V397.2096l208.384-119.5008 208.384 119.5008v235.9296z" p-id="11982" />
    </svg>
  </span>
);

/**
 * 试卷图标
 */
const ExamSvg = ({ style } : { style?: any }) => (
  <span style={style}>
    <svg viewBox="0 0 1028 1024" fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="14881" width="1em" height="1em">
      <path d="M406.753103 913.355034v-125.369379c0-29.679816 23.962483-53.737931 53.636414-53.737931h500.959632v-626.611494c0-59.074207-48.133885-107.255172-107.509701-107.255173H102.467678c49.427126 0 89.49554 40.121379 89.49554 89.43669v196.949333h-0.27954v626.483495c0 59.365517 48.095632 107.383172 107.422897 107.383172h18.150988V1019.144828c50.71154-8.524506 89.49554-52.644782 89.49554-105.791265" p-id="14882" />
      <path d="M981.149425 777.124782H505.342529c-25.438161 0-45.979954 20.627126-45.979954 46.069701v107.481747c0 45.562115-33.250575 83.386851-76.726437 90.696092v1.274115h521.738299c10.513655 0 20.713931-1.319724 30.446345-3.798805 53.134713-13.528276 92.310069-61.657747 92.310069-118.962023V823.187126c0-25.514667-20.585931-46.062345-45.981426-46.062344" p-id="14883" />
      <path d="M71.041471 3.53692c-0.073563 0-0.147126 0.004414-0.222161 0.005885C31.764598 3.661977 0 35.395678 0 74.53131v113.577196c0 23.768276 19.086713 42.760828 42.629885 42.760827H142.08V74.53131C142.082943 35.385379 110.277149 3.53692 71.042943 3.53692" p-id="14884" />
    </svg>
  </span>
);

/**
 * 带点的光标图标
 */
const CursorSvg = ({ style } : { style?: any }) => (
  <span style={style}>
    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10078" width="1em" height="1em">
      <path d="M549.302857 87.716571c0-20.150857-17.152-37.302857-37.302857-37.302857s-37.284571 17.152-37.284571 37.302857v125.147429c0 20.132571 17.152 37.284571 37.302857 37.284571 20.114286 0 37.266286-17.152 37.266285-37.302857z m-281.161143 236.982858c14.153143 14.592 38.144 14.592 52.717715 0 14.134857-14.134857 14.134857-38.125714 0-52.699429l-87.862858-88.283429c-14.134857-14.153143-38.144-14.573714-52.717714-0.438857-14.134857 14.153143-14.134857 38.144 0 52.717715z m430.281143-48.420572c-14.134857 14.573714-14.134857 38.582857 0 52.717714 14.573714 14.573714 38.582857 14.573714 53.156572 0l88.283428-88.283428a37.778286 37.778286 0 0 0 0-53.138286c-14.153143-14.153143-38.144-14.153143-52.717714 0zM731.428571 929.865143l-84.845714-198.016 108.854857 6.016c17.554286 0.841143 26.148571-18.011429 13.714286-30.866286L507.721143 439.990857c-11.574857-11.995429-28.708571-5.558857-29.568 11.574857l-4.717714 371.145143c-0.438857 18.432 20.132571 26.148571 32.146285 12.434286l69.851429-77.147429 81.846857 202.715429c4.297143 8.996571 12.854857 12.854857 21.010286 9.874286l45.860571-18.870858c8.137143-2.998857 11.556571-12.854857 7.277714-21.851428zM84.297143 478.134857c-20.150857 0-37.302857 17.152-37.302857 37.302857 0 20.132571 17.152 37.284571 37.302857 37.284572h125.568c20.132571 0 37.284571-17.152 37.284571-37.302857 0-20.132571-17.152-37.284571-37.302857-37.284572z m855.405714 74.587429c20.150857 0 37.302857-17.152 37.302857-37.302857 0-20.132571-17.152-37.284571-37.302857-37.284572h-125.129143c-20.150857 0-37.302857 17.152-37.302857 37.302857 0 20.132571 17.152 37.284571 37.302857 37.284572zM184.137143 790.125714c-14.555429 14.134857-14.555429 38.582857-0.420572 52.717715 14.134857 14.134857 38.582857 14.573714 53.138286 0.420571l88.283429-88.283429c14.153143-14.134857 14.153143-38.144 0-52.717714-13.714286-14.134857-38.144-14.134857-52.699429 0z" p-id="10079" fill="currentColor" />
    </svg>
  </span>
);

/**
 * @description: 操作手册图标
 */
const OperationSvg = ({ style }: { style?: any }) => (
  <span style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-1058 -1476.119)">
        <path className="a" d="M0,12A12,12,0,1,0,12,0,12,12,0,0,0,0,12Z" transform="translate(1058 1476.119)" fill="#fff" />
        <path className="b" d="M140.4,34.133H130.42a.7.7,0,0,0-.713.655V46.571a.7.7,0,0,0,.713.655H140.4a.7.7,0,0,0,.713-.655V34.788A.7.7,0,0,0,140.4,34.133Zm-7.405,4a.425.425,0,0,1,.56,0l1.145,1.052,2.57-2.361a.425.425,0,0,1,.56,0,.346.346,0,0,1,0,.514l-2.85,2.618a.394.394,0,0,1-.56,0l-1.425-1.309A.346.346,0,0,1,132.99,38.131Zm2.418,6.85h-2.494a.375.375,0,1,1,0-.748h2.494a.375.375,0,1,1,0,.748Zm2.494-1.964h-4.988a.375.375,0,1,1,0-.748H137.9a.375.375,0,1,1,0,.748Z" transform="translate(934.594 1446.986)" fill="#3662eb" />
      </g>
    </svg>
  </span>
);

/**
 * @description: 用户协议与隐私政策图标
 */
const AgreementSvg = ({ style }: { style?: any }) => (
  <span style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g transform="translate(-987.748 -1476.119)">
        <path d="M0,12A12,12,0,1,0,12,0,12,12,0,0,0,0,12Z" transform="translate(987.748 1476.119)" fill="#fff" />
        <g transform="translate(871.604 1361.49)">
          <path d="M133.812,601.288l-4.565,1.916a2.847,2.847,0,0,1-2.206,0l-4.565-1.916a.343.343,0,0,0-.475.315v4.267a.343.343,0,0,0,.343.343h11.6a.343.343,0,0,0,.343-.343V601.6A.344.344,0,0,0,133.812,601.288Z" transform="translate(0 -473.492)" fill="#3662eb" />
          <path d="M221.885,128.943l3.578-1.5v-6.647a.493.493,0,0,0-.494-.494h-8.375a.493.493,0,0,0-.494.494v6.647l3.578,1.5A2.851,2.851,0,0,0,221.885,128.943Zm-1.911-6.517a.157.157,0,0,1,.157-.157h3.026a.157.157,0,0,1,.157.157v.379a.157.157,0,0,1-.157.157h-3.026a.157.157,0,0,1-.157-.157Zm-1.255-.279a.469.469,0,1,1-.469.469A.469.469,0,0,1,218.719,122.146Zm-.469,2.033a.194.194,0,0,1,.193-.193h4.678a.194.194,0,0,1,.193.193v.307a.194.194,0,0,1-.193.193h-4.678a.194.194,0,0,1-.193-.193Zm0,2.1v-.307a.194.194,0,0,1,.193-.193h4.678a.194.194,0,0,1,.193.193v.307a.194.194,0,0,1-.193.193h-4.678A.194.194,0,0,1,218.251,126.281Z" transform="translate(-92.639)" fill="#3662eb" />
        </g>
      </g>
    </svg>
  </span>
);
/** 订单图标 */
const OrderSvg = (props: any) => (
  <span {...props}>
    <svg width="1em" height="1em" viewBox="0 0 12.46 14.164">
      <path fill="currentColor" d="M74.6,6.564v7.053A1.352,1.352,0,0,1,73.275,15h-9.8a1.354,1.354,0,0,1-1.329-1.377V2.209A1.355,1.355,0,0,1,63.468.832h5.626v4.83a.887.887,0,0,0,.866.9Zm-.242-.9-4.4-4.58V5.662s0,0,0,0Zm-10.026.9H67.22a.45.45,0,0,0,0-.9H64.336a.45.45,0,0,0,0,.9Zm0,3h8.076a.45.45,0,0,0,0-.9H64.336a.45.45,0,0,0,0,.9Z" transform="translate(-62.144 -0.832)" />
    </svg>
  </span>
);

/** 订单图标 */
const AlipaySvg = (props: any) => (
  <div className="flex" {...props}>
    <svg width="1em" height="1em">
      <path fill="#009fe8" d="M18,12.322V3.46A3.462,3.462,0,0,0,14.539,0H3.46A3.462,3.462,0,0,0,0,3.46V14.539A3.461,3.461,0,0,0,3.46,18H14.539a3.465,3.465,0,0,0,3.407-2.849c-.918-.4-4.9-2.115-6.968-3.1C9.4,13.956,7.749,15.1,5.259,15.1s-4.152-1.534-3.952-3.411c.131-1.231.976-3.244,4.646-2.9a16.533,16.533,0,0,1,4.4,1.064,12.5,12.5,0,0,0,1-2.448H4.36V6.715H7.822V5.469H3.6V4.707H7.821v-1.8a.335.335,0,0,1,.348-.281H9.9V4.707h4.5V5.47H9.9V6.714H13.57a14.167,14.167,0,0,1-1.491,3.738c1.066.387,5.92,1.87,5.92,1.87ZM4.984,13.914c-2.631,0-3.046-1.66-2.907-2.354A2.25,2.25,0,0,1,4.439,9.967a10.971,10.971,0,0,1,4.993,1.31,5.839,5.839,0,0,1-4.448,2.637Z" transform="translate(0 0)" />
    </svg>
  </div>
);
/** 订单图标 */
const WeChatSvg = (props: any) => (
  <div className="flex" {...props}>
    <svg width="1.1875em" height="1em">
      <path fill="#15ba11" d="M8.3,10.987a.648.648,0,0,1-.558-.31l-.042-.087L4.348,6.168a.293.293,0,0,1-.032-.127.312.312,0,0,1,.32-.3.334.334,0,0,1,.192.06L8.491,7.985a1.006,1.006,0,0,0,.852.093l8.1-4.878A10.545,10.545,0,0,0,9.6,0C4.3,0,0,3.384,0,7.557a7.022,7.022,0,0,0,3.317,5.711.6.6,0,0,1,.269.492.7.7,0,0,1-.034.194l-.433,1.523a.829.829,0,0,0-.052.221.311.311,0,0,0,.32.3.373.373,0,0,0,.184-.056l2.1-1.146a1.048,1.048,0,0,1,.51-.14,1.06,1.06,0,0,1,.282.039,11.977,11.977,0,0,0,3.134.415c5.3,0,9.6-3.385,9.6-7.556a6.281,6.281,0,0,0-1.094-3.5L8.653,10.884l-.07.038a.658.658,0,0,1-.288.066Z" />
    </svg>
  </div>
);
/**
 * 自定义icon图标
 */
export {
  StudySvg,
  BellSvg,
  ManageSvg,
  ExamSvg,
  CursorSvg,
  OperationSvg,
  AgreementSvg,
  OrderSvg,
  AlipaySvg,
  WeChatSvg,
};
