/*
 * @Description:
 * @Autor: CYF
 * @Date: 2022-03-31 09:41:19
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-25 17:55:29
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getArticleList,
  getTopArticleList,
  getArticle,
} from '@/api/server/information';
import { groupArray } from '@/tools/normal';

class InformationStore {
  // 资讯动态列表
  informationList: any[] = [];

  // 资讯动态置顶列表
  informationTopList: any[] = [];

  // 资讯动态详情
  informationInfo: any = {};

  // 分页页码
  inPageNum: number = 1;

  // 分页页数
  inPageSize: number = 10;

  // 分页总数
  inTotal: number = 0;

  // 文章详情
  articleInfo: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取资讯动态列表
   */
  @action getInformationList = async (data: any) => {
    const res: any = await getArticleList(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.informationList = res.result?.data;
        this.inPageNum = res.result?.page;
        this.inTotal = res.result?.total;
      }
    });
  }

  /**
   * 获取资讯动态置顶列表
   */
  @action getInformationTopList = async () => {
    const res: any = await getTopArticleList();
    runInAction(() => {
      if (res.state.value === 0) {
        if (res.result) {
          this.informationTopList = groupArray(res.result, 3);
        }
      }
    });
  }

  /**
   * 获取资讯动态详情
   */
  @action getArticleInfo = async (data: any) => {
    const res: any = await getArticle(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.articleInfo = res.result;
      }
    });
  }
}

export default new InformationStore();
