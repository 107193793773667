/*
 * @Descripttion:
 * @version:
 * @Author: Fanzy
 * @Date: 2022-03-14 10:14:46
 * @LastEditors: Fanzy
 * @LastEditTime: 2022-03-14 15:38:26
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getUserInfo,
  getUnitUserInfo,
} from '@/api/server/userInfo';
import { message } from 'antd';

/**
 * 用户信息同意状态
 */
class UserInfoStore {
  // 用户个人信息
  userInfo: any = {};

  // 用户类型
  userRoleType: any = Number(localStorage.getItem('userType'));

  // 是否服务平台单点登录
  isService: any = localStorage.getItem('isService');

  // 用户权限
  userPermission: any = [...JSON.parse(localStorage.getItem('permission') || '[]')];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取用户信息详情
   */
  @action getUserInfoData = async (data: any = {}) => {
    const res: any = this.userRoleType === 1 ? await getUserInfo({ ...data, isBrief: false }) : await getUnitUserInfo(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.userInfo = res.result;
      } else {
        message.error('用户信息详情获取失败!');
      }
    });
  }

  /**
   * 设置用户信息详情
   */
  @action setUserInfoData = (data: any) => {
    runInAction(() => {
      this.userInfo = { ...this.userInfo, ...data };
    });
  }
}

export default new UserInfoStore();
