import { message } from 'antd';
import ajax from '../ajax';

// 获取订单列表
export const getOrderList = async (data: any) => {
  const res: any = await ajax('/v3/admin/admin/order/getOrderListData', data, 'GET');
  const { total = 0, list = [] } = res.result ?? {};
  if (res.state.value !== 0) {
    message.info(res.message);
  }
  console.log({
    list,
    total,
  });
  return {
    list,
    total,
  };
};
// 退款申请审核页详情
export const getRefundAuditDetails = async (data: any) => {
  const res: any = await ajax('/v3/admin/admin/order/getRefundAuditDetails', data, 'POST');
  if (res.state.value !== 0) {
    message.info(res.message);
    return {};
  }
  return res?.result;
};
// 订单退款
export const refundOrder = async (data: any) => {
  const res: any = await ajax('/v3/admin/admin/order/refundOrder', data, 'POST');
  if (res.state.value !== 0) {
    message.info(res.message);
    return;
  }
  message.success(res.message);
};
// 订单拒绝退款
export const refuseOrder = async (data: any) => {
  const res: any = await ajax('/v3/admin/admin/order/refuse', data, 'POST');
  if (res.state.value !== 0) {
    message.info(res.message);
    return;
  }
  message.success(res.message);
};
// 查看订单
export const getOrderDetail = async (data: any) => {
  const res: any = await ajax('/v3/admin/admin/order/getOrderDetails', data, 'POST');
  if (res.state.value !== 0) {
    message.info(res.message);
    return {};
  }
  return res?.result;
};
// 备注保存编辑
export const saveOrderRemarks = async (data: any) => {
  const res: any = await ajax('/v3/admin/admin/order/saveOrderRemarks', data, 'POST');
  if (res.state.value !== 0) {
    message.info(res.message);
    return;
  }
  message.success(res.message);
};
// 订单退款记录
export const getOrderRefundList = async (data: any) => {
  const res: any = await ajax('/v3/admin/admin/order/getOrderRefundList', data, 'GET');
  if (res.state.value !== 0) {
    message.info(res.message);
    return [];
  }
  return res?.result;
};
