import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import { getOrderList } from '@/api/admin/orderManagement';

class AdminOrderManagementStore {
  // 当前分页
  pageNum: number = 1;

  // 当前每页展示数
  pageSize: number = 10;

  // 当前数据总数
  total: number = 1;

  // 当前搜索关键字
  keyWord: string = '';

  // 当前搜索数据状态
  status: number|string|undefined = undefined;

  // 当前搜索起始时间
  beginDate: number|string|undefined = undefined;

  // 当前搜索结束时间
  endDate: number|string|undefined = undefined;

  // 当前表格loading
  loading: boolean = false;

  // 当前数据
  dataList: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /**
  * 获取订单数据列表
  */
    @action getOrderList = async (data: any) => {
      this.loading = true;
      const defaultData = {
        keyword: this.keyWord,
        status: this.status,
        beginDate: this.beginDate,
        endDate: this.endDate,
      };
      const res: any = await getOrderList({ ...defaultData, ...data });
      if (res.state.value === 0) {
        runInAction(() => {
          this.pageNum = res.result.page;
          this.dataList = res.result.data;
          this.total = res.result.total;
          this.loading = false;
          if (data.status !== undefined) {
            this.status = data.status !== '' ? data.status : undefined;
          }
          if (data.keyword !== undefined) {
            this.keyWord = data.keyword !== '' ? data.keyword : undefined;
          }
        });
        return true;
      }
      return false;
    }
}
export default new AdminOrderManagementStore();
