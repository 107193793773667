import React from 'react';

import aboutStore from '@/store/modal/aboutStore';
import UserInfoStore from '@/store/modal/UserInfoStore';
import homeStore from '@/store/modal/homeStore';
import InformationStore from '@/store/modal/InformationStore';
import loginStore from '@/store/modal/loginStore';
import myExamStore from '@/store/modal/myExamStore';
import onlineTrainingDetailStore from '@/store/modal/onlineTrainingDetailStore';
import registerStore from '@/store/modal/registerStore';
import serverHomeStore from '@/store/modal/serverHomeStore';
import userCenterUnitTrainStore from '@/store/modal/userCenterUnitTrainStore';
import userCenterUserTrainStore from '@/store/modal/userCenterUserTrainStore';

import MultiSelectStore from '@/store/modal/MultiSelectStore';

import adminDatatDictionaryStore from '@/store/modal/adminDatatDictionaryStore';
import adminEmployerManagementStore from '@/store/modal/adminEmployerManagementStore';
import adminExamInstructionsStore from '@/store/modal/adminExamInstructionsStore';
import adminInformationStore from '@/store/modal/adminInformationStore';
import adminMessageStore from '@/store/modal/adminMessageStore';
import adminOrderManagementStore from '@/store/modal/adminOrderManagementStore';
import adminParameterManagementStore from '@/store/modal/adminParameterManagementStore';
import adminSystemLogStore from '@/store/modal/adminSystemLogStore';
import adminSystemManagerUserStore from '@/store/modal/adminSystemManagerUserStore';
import adminTrainingManagementStore from '@/store/modal/adminTrainingManagementStore';
import certificateManagementStore from '@/store/modal/certificateManagementStore';
import questionManagementStore from '@/store/modal/questionManagementStore';
import structureManagementStore from '@/store/modal/structureManagementStore';

import DemoListStore from '@/store/modal/DemoListStore';
import demoTableStore from '@/store/modal/demoTableStore';

// 自动导入不好溯源
// const storeFiles = require.context('./modal', true, /\.ts$/);

// const map: { [key: string]: string } = {};

// storeFiles.keys().forEach((key) => {
//   const keyArr = key.split('/');
//   keyArr.shift();
//   map[keyArr.join('.').replace(/\.ts$/g, '')] = storeFiles(key).default;
// });

/** 手动导入 */
const handleImportStores = {
  /** 关于我们全局状态 */
  aboutStore,
  /** 用户信息全局状态 */
  UserInfoStore,
  /** 首页全局状态 */
  homeStore,
  /** 资讯动态全局状态 */
  InformationStore,
  /** 登录全局状态 */
  loginStore,
  /** 我的考试全局状态 */
  myExamStore,
  /** 培训详情全局状态 */
  onlineTrainingDetailStore,
  /** 注册全局状态 */
  registerStore,
  /** 前台通用全局状态 */
  serverHomeStore,
  /** 单位用户培训全局状态 */
  userCenterUnitTrainStore,
  /** 个人用户培训全局状态 */
  userCenterUserTrainStore,
  /** demo列表全局状态 */
  DemoListStore,
  /** demo表格全局状态 */
  demoTableStore,
  /** 区域下拉选择全局状态 */
  MultiSelectStore,
  /** 后台-数据字典全局状态 */
  adminDatatDictionaryStore,
  /** 后台-用人单位底库全局状态 */
  adminEmployerManagementStore,
  /** 后台-考试须知全局状态 */
  adminExamInstructionsStore,
  /** 后台-资讯动态全局状态 */
  adminInformationStore,
  /** 后台-关于我们全局状态 */
  adminMessageStore,
  /** 后台-订单管理全局状态 */
  adminOrderManagementStore,
  /** 后台-参数设置全局状态 */
  adminParameterManagementStore,
  /** 后台-系统日志全局状态 */
  adminSystemLogStore,
  /** 后台-用户管理全局状态 */
  adminSystemManagerUserStore,
  /** 后台-培训管理全局状态 */
  adminTrainingManagementStore,
  /** 后台-证书管理全局状态 */
  certificateManagementStore,
  /** 后台-试题管理全局状态 */
  questionManagementStore,
  /** 后台-试卷管理全局状态 */
  structureManagementStore,
};

const storesContext = React.createContext({
  // ...map,
  ...handleImportStores,
});

const useStores = () => React.useContext(storesContext);

export { handleImportStores as stores, useStores };
