/*
 * @Date: 2022-03-10 08:07:34
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-29 09:24:48
 * @Description: 功能描述
 * @FilePath: \training-platform\src\router\serverRoutesConfig.tsx
 */
import { ReactNode, lazy } from 'react';

// 首页
const Home = lazy(() => import('@/pages/home'));

// 个人中心(个人/单位)
const UserCenterManagement = lazy(() => import('@/pages/userCenter'));
// 在线培训
const OnlineTraining = lazy(() => import('@/pages/onlineTraining'));
const OnlineTrainingDetail = lazy(() => import('@/pages/onlineTraining/detail'));

// 资讯动态
const Information = lazy(() => import('@/pages/information'));
// 资讯动态详情
const InformationInfo = lazy(() => import('@/pages/informationInfo'));

// 联系我们
const AboutUs = lazy(() => import('@/pages/aboutUs'));

interface RouteConfig {
  path: string,
  key: string,
  component: ReactNode,
  redirect?: string,
  children?: RouteConfig[],
  switch?: boolean
}

const routesConfig: RouteConfig[] = [
  {
    path: '/',
    key: '首页',
    component: Home,
    redirect: '/home',
    children: [
      {
        path: 'home',
        key: '首页',
        component: Home,
      },
      {
        path: 'training',
        key: '在线培训',
        component: OnlineTraining,
        children: [
          {
            path: '/detail/:id',
            key: '在线培训详情',
            switch: false,
            component: OnlineTrainingDetail,
          },
        ],
      },
      {
        path: 'news',
        key: '资讯动态',
        component: Information,
        children: [
          {
            path: '/detail/:id',
            key: '资讯动态详情',
            switch: false,
            component: InformationInfo,
          },
        ],
      },
      {
        path: 'contact',
        key: '联系我们',
        component: AboutUs,
      },

      {
        path: 'user',
        key: '个人中心',
        component: () => <UserCenterManagement userRoleType={1} />,
        switch: true,
      },
      {
        path: 'userUnit',
        key: '个人中心',
        component: () => <UserCenterManagement userRoleType={2} />,
        switch: true,
      },
    ],
  },
];
export default routesConfig;
