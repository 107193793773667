import { ReactNode } from 'react';

interface menuConfig {
  path: string,
  key: string,
  icon?: ReactNode,
  children?: menuConfig[],
  perm?: string,
}

const menuConfigs: menuConfig [] = [
  // {
  //   path: '/admin/home',
  //   key: '数据统计',
  //   perm: 'home:view',
  // },
  {
    path: '/admin/trainingManagement',
    key: '培训管理',
    perm: 'training:view',
  },
  {
    path: '/admin/courseManagement/group',
    key: '课程组管理',
    perm: 'training_course:view',
  },
  {
    path: '/admin/courseManagement/list',
    key: '课程管理',
    perm: 'training_course:view',
  },
  {
    path: '/admin/question',
    key: '试题管理',
    perm: 'question:view',
  },
  {
    path: '/admin/certificate',
    key: '证书管理',
    perm: 'cert:view',
  },
  {
    path: '/admin/order',
    key: '订单管理',
    perm: '',
  },
  {
    path: '/admin/system',
    key: '系统管理',
    perm: 'sys:view',
    children: [
      {
        path: '/admin/system/managerUser',
        key: '用户管理',
        perm: 'sys:user:view',
      },
      {
        path: '/admin/system/managerRole',
        key: '角色管理',
        perm: 'sys:role:view',
      },
      {
        path: '/admin/system/managerParam',
        key: '参数管理',
      },
      {
        path: '/admin/system/managerDictionary',
        key: '数据字典',
      },
      {
        path: '/admin/system/managerLog',
        key: '系统日志',
      },
      {
        path: '/admin/system/managerUnit',
        key: '用人单位底库管理',
      },
    ],
  },
  {
    path: '/admin/newsInfo',
    key: '资讯动态管理',
    perm: 'web:news:view',
  },
  {
    path: '/admin/contact',
    key: '关于我们管理',
    perm: 'web:about:view',
  },
  {
    path: '/admin/examInstructions',
    key: '考试须知管理',
    perm: 'paper_instructions:view',
  },
];

export default menuConfigs;
