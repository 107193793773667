/*
 * @Description: 弹框
 * @Autor: CYF
 * @Date: 2022-03-10 15:33:53
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-24 10:12:52
 */

import { Modal } from 'antd';
import { Children, useEffect, useState } from 'react';

import './index.less';

interface CustomPopcompProps {
  title?: string, // 弹框标题
  className?: string,
  customConfig?: {}, // 自定义配置
  isshow: boolean, // 是否显示弹框
  setisshow: Function, // 设置是否显示弹框
  children: Object, // 获得的子节点
  notSelfClose?: boolean, // 自身是否关闭
}

/**
 * @description: 自定义弹框
 * @param {CustomPopcompProps} props
 */
export default function CustomPopcomp(props: CustomPopcompProps) {
  const [isModalVisible, setisModalVisible] = useState(false);

  useEffect(() => {
    setisModalVisible(props.isshow);
  }, [props.isshow]);
  return (
    <Modal
      title={props.title}
      className={props.className ? `custom-popcomp ${props.className}` : 'custom-popcomp'}
      centered
      visible={isModalVisible}
      onOk={() => {
        if (!props.notSelfClose) {
          setisModalVisible(false);
        }
        props.setisshow(false);
      }}
      onCancel={() => {
        if (!props.notSelfClose) {
          setisModalVisible(false);
        }
        props.setisshow(false);
      }}
      getContainer={false} // 指定 Modal 挂载的 HTML 节点, false 为挂载在当前 dom
      // forceRender // 强制渲染Modal
      destroyOnClose // 关闭时销毁子元素,若是 form 需设置 preserve 为 false 生效
      {...props.customConfig}
    >
      {Children.map(props.children, (child) => child)}
    </Modal>
  );
}

// 初始值
CustomPopcomp.defaultProps = {
  title: '重置密码',
};
