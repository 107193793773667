import ajax from '../ajax';

// 获取培训列表
export const getDataList = (data: any) => ajax('/v3/admin/trainingManage/train/getTrainingList', data, 'GET');
// 获取已报名的培训的工作类别
export const getAllWorkType = (data: any) => ajax('/v3/admin/trainingManage/train/getTrainingWorkType', data, 'GET');
// 获取培训可选择的目标证书列表
export const getCertificateList = (data: any = {}) => ajax('/v3/admin/trainingManage/train/getCertificateList', data, 'GET');
// 删除培训
export const deleteData = (data: any) => ajax('/v3/admin/trainingManage/train/removeBatchTrainingInfo', data, 'POST');
// 新增培训
export const addData = (data: any) => ajax('/v3/admin/trainingManage/train/addTrainingDetail', data, 'POST');
// 修改培训
export const editData = (data: any) => ajax('/v3/admin/trainingManage/train/updateTrainingDetail', data, 'POST');
// 获取培训详情
export const getDataDetail = (data: any) => ajax('/v3/admin/trainingManage/train/getTrainingDetail', data, 'GET');
// 上下架培训
export const onShelfData = (data: any) => ajax('/v3/admin/trainingManage/train/changeTrainingStatus', data, 'POST');
// 更新培训-课程列表信息
export const saveCouresList = (data: any) => ajax('/v3/admin/trainingManage/course/saveOrUpdateNewTrainCourse', data, 'POST');
// 获取培训详情-课程列表信息
export const getCouresList = (data: any = {}) => ajax('/v3/admin/trainingManage/course/getNewTrainCourse', { ...data, limit: 1000 }, 'GET');
// 获取培训详情-个人报名列表
export const getPostPersonList = (data: any) => ajax('/v3/admin/trainingManage/train/getTrainingPersonEnrollList', data, 'GET');
// 获取培训详情-单位报名列表
export const getPostUnitList = (data: any) => ajax('/v3/admin/trainingManage/train/getTrainingUnitEnrollList', data, 'GET');

// 个人报名-退回
export const userReject = (data: any) => ajax('/v3/admin/trainingManage/train/sendBack', data, 'POST');
// 个人报名-删除
export const userDelete = (data: any) => ajax('/v3/admin/trainingManage/train/deleteEnrollDetail', data, 'POST');
// 个人报名-批量操作
export const userBatch = (data: any) => ajax('/v3/admin/trainingManage/train/enrollDetailOptBatch', data, 'POST');

// 单位报名-退回
export const unitReject = (data: any) => ajax('/v3/admin/trainingManage/train/sendBackCrop', data, 'POST');
// 单位报名-删除
export const unitDelete = (data: any) => ajax('/v3/admin/trainingManage/train/deleteEnroll', data, 'POST');
// 单位报名-批量操作
export const unitBatch = (data: any) => ajax('/v3/admin/trainingManage/train/enrollOptBatch', data, 'POST');

// 批量生成证书
export const unitGenerateMulity = (data: any) => ajax('/v3/admin/tuCertificate/generateBatchCertificate', data, 'GET');
// 批量下载证书
export const unitDownloadMulity = (data: any) => ajax('/v3/admin/tuCertificate/packageCert', data, 'POST', {
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'blob',
}, true);

// 监管-导入个人报名
export const adminEnrollImport = (data: { fileId?: string, tid?: string }) => ajax('/v3/admin/adminEnrollImport/staff', data, 'POST');
