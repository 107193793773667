/*
 * @Description: 登录
 * @Autor: CYF
 * @Date: 2022-03-14 10:17:29
 * @LastEditors: CYF
 * @LastEditTime: 2023-10-08 13:52:33
 */

import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getVerificationCode,
  checkVerificationCode,
  login,
  adminLogin,
  getEmailVcode,
  checkEmail,
  resetPasswordByEmail,
  resetPasswordByOld,
  resetAdminPassWordByOld,
  resetPassword,
  resetAdminPassword,
  getPublicKey,
  loginCas, // 服务平台单点登录
} from '@/api/server/login';
import { message } from 'antd';

export interface userLoginData {
  username: string; // 账号
  password: string; // 密码
  verifyCode?: string; // 验证码
}

/**
 * @description: 表示LoginStore状态管理类
 */
class LoginStore {
  // 验证码
  verifyCode: string = '';

  // 随机生成的key
  randomKey: number = NaN;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取验证码
   */
  @action getVerifyCode = async () => {
    const tmpKey = new Date().getTime();
    const defaultData = {
      key: tmpKey, // 时间戳
    };
    const res: any = await getVerificationCode({ ...defaultData });
    runInAction(() => {
      this.randomKey = tmpKey;
      if (res.state?.value === 0) {
        this.verifyCode = res.result;
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * @description: 校验验证码
   */
  @action checkVerifyCode = async (data: { vode: string }) => {
    const defaultData = {
      key: this.randomKey, // 时间戳
    };
    const res: any = await checkVerificationCode({ ...defaultData, ...data });
    return res;
  };

  /**
   * @description: 获取公钥
   */
  @action getPublicKey = async () => {
    const tmpKey = new Date().getTime();
    const defaultData = {
      uuid: tmpKey, // 时间戳
    };
    const res: any = await getPublicKey({ ...defaultData });
    runInAction(() => {
      this.randomKey = tmpKey;
    });
    if (res.state?.value === 0) {
      return { ...res, key: tmpKey };
    }
    message.error(res.message);
    return false;
  };

  /**
   * @description: 登录
   * @param {object} data 传给后台的参数
   * @param {function} callback 回调函数
   * @return {*}
   */
  @action userLogin = async (
    data: userLoginData & {
      userType: 1 | 2; // 用户类型 1：个人 2：单位
    },
    callback: Function,
  ) => {
    const defaultData = {
      uuid: this.randomKey, // 时间戳
    };
    const res: any = await login({ ...defaultData, ...data });
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
      await callback(false);
    }
  }

  /**
   * @description: 后台登录
   * @param {object} data 传给后台的参数
   * @param {function} callback 回调函数
   * @return {*}
   */
  @action adminLogin = async (
    data: userLoginData,
    callback: Function,
  ) => {
    const defaultData = {
      uuid: this.randomKey, // 时间戳
    };
    const res: any = await adminLogin({ ...defaultData, ...data });
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
      await callback(false);
    }
  }

  /**
   * @description: 服务平台单点登录
   * @param {string} token 传给后台的参数
   * @return {*}
  */
  @action casLogin = async (token: string, router: string) => {
    const res: any = await loginCas({ token });

    if (!res.result?.token) { // 登录失败
      message.error(res?.message);
      return false;
    }
    message.success(res?.message);
    localStorage.clear();
    // 保存登录信息
    localStorage.setItem('isFirst', JSON.stringify(true));
    localStorage.setItem('token', res.result?.token);
    localStorage.setItem('refreshToken', res.result?.refreshToken);
    localStorage.setItem('userType', res.result?.user?.user?.userType);
    localStorage.setItem('name', res.result?.user?.user?.username || res.result?.user?.user?.name || '暂无'); // 用户名
    // 保存用户权限
    localStorage.setItem('permission', JSON.stringify(res.result?.user?.permissionsSet || []));
    // 保存用户角色
    localStorage.setItem('roles', JSON.stringify(res.result?.user?.roleSet || []));
    // 是否是服务平台单点登录
    localStorage.setItem('isService', 'true');
    window.location.href = router;
  }

  /**
   * @description: 获取邮箱验证码
   * @param {any} data 请求参数
   */
  @action getEmailVcode = async (
    data: { email: string },
    callback: Function,
  ) => {
    const res: any = await getEmailVcode(data);
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 校验邮箱
   * @param {object} data
   * @return {*}
   */
  @action checkEmailVcode = async (
    data: { email: string, code: string },
    callback: Function,
  ) => {
    const res: any = await checkEmail(data);
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  }

  /**
   * @description: 重置密码
   * @param {object} data
   * @return {*}
   */
  @action resetPasswordByEmail = async (
    data: {
      password: string;
      email: string;
      code: string;
    },
    callback: Function,
  ) => {
    const defaultData = {
      uuid: this.randomKey, // 时间戳
    };
    const res: any = await resetPasswordByEmail({ ...defaultData, ...data });
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  }

  /**
   * @description: 重置密码
   * @param {object} data
   * @return {*}
   */
  @action resetPasswordByOld = async (
    data: {
      newPassword: string;
      oldPassword: string;
    },
    callback: Function,
  ) => {
    const defaultData = {
      uuid: this.randomKey, // 时间戳
    };
    const res: any = await resetPasswordByOld({ ...defaultData, ...data });
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  }

  /**
   * @description: 重置密码
   * @param {object} data
   * @return {*}
   */
  @action resetAdminPassWordByOld = async (
    data: {
      newPassword: string;
      oldPassword: string;
    },
    callback: Function,
  ) => {
    const defaultData = {
      uuid: this.randomKey, // 时间戳
    };
    const res: any = await resetAdminPassWordByOld({ ...defaultData, ...data });
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  }

  /**
   * @description: 重置密码
   * @param {object} data
   * @return {*}
   */
  @action resetPassword = async (
    data: {
      password: string;
    },
    callback: Function,
  ) => {
    const defaultData = {
      uuid: this.randomKey, // 时间戳
    };
    const res: any = await resetPassword({ ...defaultData, ...data });
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  }

  /**
   * @description: 重置密码
   * @param {object} data
   * @return {*}
   */
  @action resetAdminPassword = async (
    data: {
      password: string;
    },
    callback: Function,
  ) => {
    const defaultData = {
      uuid: this.randomKey, // 时间戳
    };
    const res: any = await resetAdminPassword({ ...defaultData, ...data });
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  }

  /**
   * @description: 退出登录
   * @param {object} data
   * @return {*}
   */
  @action signOut = async (
    data: {},
    callback: Function,
  ) => {
    localStorage.clear();
    localStorage.setItem('token', '');
    localStorage.setItem('refreshToken', '');
    localStorage.setItem('userType', '');
    localStorage.setItem('permission', '');
    localStorage.setItem('name', '');
    localStorage.setItem('roles', '');
    await callback();
    // const res: any = await signOut(data);
    // if (res.status === 0) {
    //   localStorage.setItem('token', '');
    //   localStorage.setItem('refreshToken', '');
    //   localStorage.setItem('userType', '');
    //   await callback(res);
    // } else {
    //   message.error('');
    // }
  }
}

export default new LoginStore();
