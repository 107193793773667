/*! 培训管理接口
 * 无需权限
 */
import ajax from '@/api/ajax';
import { message } from 'antd';
import type {
  CourseGroupInfo,
  Params, Result, TrainingDetail, TrainingListData, WorkType,
} from './type.d';
import { getPersonalTrainingList, postPersonalSign } from './personal';

/** 查看培训管理列表  */
export const getTrainingList = async (data: Params) => {
  let url;
  const userType = localStorage.getItem('userType');
  switch (userType) {
    case '1':
      url = '/v2/www/personal/train/getTrainingList';
      break;
    case '2':
      url = '/v2/www/crop/train/getTrainingList';
      break;
    default:
      url = '/v2/www/api/train/getTrainingList';
      break;
  }
  const res = await ajax(url, data, 'GET') as Result<TrainingListData>;
  if (res.state.value !== 0) {
    message.error(res.message);
  }
  const { data: list = [], total } = res.result ?? {};
  return {
    list,
    total,
  };
};

/** 查看培训基本信息  */
export const getTrainingDetail = (data: {
  trainingId?: string
}) => ajax('/v2/www/api/train/getTrainingBaseInfo', data, 'GET') as Promise<Result<TrainingDetail>>;

/** 查看培训-课程组 信息  */
export const getTrainingCourseGroup = (data: {
  tid?: string
}) => ajax('/v2/www/api/train/getTrainingCourseGroup', data, 'GET') as Promise<Result<CourseGroupInfo>>;

/** 查看培训-课程组-课程 信息  */
export const getTrainingCourse = (data: {
  courseGroupId: string
  tid?: string
}) => ajax('/v2/www/api/train/getTrainingCourse', data, 'GET') as Promise<Result<any>>;

/** 获取所有已上架培训的工作类别  */
export const getTrainingWorkType = () => ajax('/v2/www/api/train/getTrainingWorkType', 'GET') as Promise<Result<WorkType[]>>;

export {
  getPersonalTrainingList,
  postPersonalSign,
};
