/*
 * @Description: 登录
 * @Autor: CYF
 * @Date: 2022-03-10 15:33:53
 * @LastEditors: CYF
 * @LastEditTime: 2023-10-08 14:18:00
 */

import {
  Button, Divider, Form, Input, message, Modal, Space, Tabs,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStores } from '@/store';
import { userLoginData } from '@/store/modal/loginStore';
import CustomPopover from '@/otherPages/login/components/resetPopover';
import { rsaEncrypt } from '@/tools/tool';

import browserIcon1 from '../../assets/images/login/chrome.png';
import browserIcon2 from '../../assets/images/login/firefox.png';
import browserIcon3 from '../../assets/images/login/360.png';
import './index.less';

export default observer(() => {
  const { TabPane } = Tabs;
  const location: any = useLocation();
  const stores = useStores();
  const { loginStore } = stores;
  const {
    getVerifyCode,
    checkVerifyCode,
    verifyCode,
    getPublicKey,
    userLogin,
    adminLogin,
  } = loginStore;

  // 当前tab的key
  const [activeKey, setactiveKey] = useState(location.state?.activeKey || 'person');
  const [routerPath, setrouterPath] = useState('');
  // 是否显示弹框
  const [isModalVisible, setisModalVisible] = useState(false);
  // 默认是否显示修改密码(弹框内部)
  const [defaultIsChangePassword, setdefaultIsChangePassword] = useState(false);
  // 是否正在登录
  const [loading, setLoading] = useState(false);

  // 登录框显示数据列表
  const formData = [
    {
      tabname: '个人用户',
      key: 'person',
      name: 'person',
      placeholder: '请输入您的个人邮箱或身份证号',
      register: '/register',
    },
    {
      tabname: '单位用户',
      key: 'unit',
      name: 'unit',
      placeholder: '请输入您的单位全称',
      register: '/register',
    },
    {
      tabname: '后台用户',
      key: 'backstage',
      name: 'backstage',
      placeholder: '请输入您的账号',
    },
  ];
  useEffect(() => {
    // 获取验证码
    getVerifyCode();
  }, []);

  /**
   * @description: tab改变事件
   * @param {string} key
   * @return {*}
   */
  const onTabChange = (key: string) => {
    // 设为默认值
    if (defaultIsChangePassword) {
      setdefaultIsChangePassword(false);
    }

    setactiveKey(key);
    // 获取验证码
    getVerifyCode();
  };

  /**
   * @description: 登录的回调函数
   * @param {any} res 请求返回的值
   */
  const callback = (res: any) => {
    if (res) {
      let tmpType: 'person' | 'unit' | 'admin' | undefined;
      let router = '/';
      switch (activeKey) {
        case 'person': tmpType = 'person'; router = '/home'; break;
        case 'unit': tmpType = 'unit'; router = '/home'; break;
        case 'backstage': tmpType = 'admin'; router = '/admin/home'; break;
        default: router = '/home'; break;
      }
      // 保存登录信息
      localStorage.setItem('isFirst', JSON.stringify(true));
      localStorage.setItem('token', res.result?.token);
      localStorage.setItem('refreshToken', res.result?.refreshToken);
      localStorage.setItem('userType', tmpType === 'admin' ? 3 : res.result?.user?.user?.userType);
      localStorage.setItem('name', res.result?.user?.user?.username || res.result?.user?.user?.name || '暂无'); // 用户名
      // 保存用户权限
      localStorage.setItem('permission', JSON.stringify(res.result?.user?.permissionsSet || []));
      // 保存用户角色
      localStorage.setItem('roles', JSON.stringify(res.result?.user?.roleSet || []));

      // 是否需要改密码
      if (res.result?.user?.user?.isChangePwd === 1) { // 1-默认密码需要修改密码
        setdefaultIsChangePassword(true);
        setisModalVisible(true);
        setrouterPath(router);
      } else if (res.result?.user?.user?.isChangePwd === 2) { // 2-长时间未改密码提醒需要修改
        Modal.confirm({
          title: '提醒',
          content: (
            <p>您已较长时间未更换密码，为确保安全是否现在去重置密码？</p>
          ),
          onOk: () => {
            setdefaultIsChangePassword(true);
            setisModalVisible(true);
            setrouterPath(router);
          },
          onCancel: () => {
            window.location.href = router;
          },
        });
      } else { // 0-不需要
        window.location.href = router;
      }
    } else {
      getVerifyCode();
    }
    setLoading(false);
  };

  /**
   * @description: 登录按钮点击事件
   * @param {userLoginData} values 登录的用户信息
   */
  const onFinish = (values: userLoginData) => {
    let tmpType: number = NaN;
    if (activeKey === 'person') {
      tmpType = 1;
    } else if (activeKey === 'unit') {
      tmpType = 2;
    }

    // 校验验证码
    checkVerifyCode(JSON.parse(JSON.stringify({ vode: values.verifyCode }))).then((res: any) => {
      if (res?.state?.value === 0 && res?.result) {
        return getPublicKey();
      }
      if (res?.result === false) {
        message.error('验证码错误请重新输入');
      } else {
        message.error(res?.message);
      }
      getVerifyCode();
      return false;
    }).then((res: any) => {
      if (res) {
        if (res?.result) {
          return res.result;
        }
        message.error(res?.message);
      }
      return false;
    }).then((res: any) => {
      if (res) {
        const data = {
          username: values.username,
          password: rsaEncrypt(values.password, res),
        };
        setLoading(true);
        // 登录
        if (activeKey === 'backstage') {
          adminLogin(JSON.parse(JSON.stringify({ ...data })), callback);
        } else {
          userLogin(JSON.parse(JSON.stringify({ ...data, userType: tmpType })), callback);
        }
      }
    });
  };

  return (
    <div className="login-page">
      <Tabs defaultActiveKey="personal" onChange={onTabChange}>
        {formData.map((item) => (
          <TabPane tab={item.tabname} key={item.key}>
            <Form
              className="login-form"
              layout="vertical"
              name={item.name} // 表单名称，会作为表单字段 id 前缀使用
              scrollToFirstError // 提交失败自动滚动到第一个错误字段
              onFinish={onFinish} // 提交表单且数据验证成功后回调事件
              onFinishFailed={
                (errorInfo) => { console.log('Failed:', errorInfo); }
              } // 提交表单且数据验证失败后回调事件
            >
              <Form.Item
                label="账号"
                name="username"
                hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
                required={false}
                rules={[{ required: true, message: item.placeholder }]}
              >
                <Input placeholder={item.placeholder} />
              </Form.Item>
              <Form.Item
                label="密码"
                name="password"
                hasFeedback
                required={false}
                rules={[{ required: true, message: '请输入您的登录密码' }]}
              >
                <Input.Password placeholder="请输入您的登录密码" />
              </Form.Item>
              <Form.Item
                label="验证码"
                name="verifyCode"
                required={false}
                rules={[{ required: true, message: '请输入验证码' }]}
              >
                <div className="verify-code">
                  <Input placeholder="请输入验证码" />
                  <Button
                    className="verify-code-img"
                    onClick={() => {
                      getVerifyCode();
                    }}
                  >
                    <img src={verifyCode} alt="验证码" />
                  </Button>
                </div>
              </Form.Item>
              {item.key !== 'backstage' ? (
                <Form.Item className="reset-password">
                  <Button
                    type="text"
                    className="reset-password-btn"
                    onClick={() => {
                      setdefaultIsChangePassword(false);
                      setisModalVisible(true);
                    }}
                  >
                    忘记密码
                  </Button>
                </Form.Item>
              ) : null}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  立即登录
                </Button>
              </Form.Item>
              {item.key !== 'backstage' ? (
                <Form.Item className="register">
                  <Link
                    to={{
                      pathname: item.register,
                      state: { activeKey: item.key },
                    }}
                  >
                    {item.tabname}
                    注册
                  </Link>
                </Form.Item>
              ) : null}
              <Divider />
              <Form.Item>
                <Space className="browser">
                  <p>推荐使用谷歌、火狐、360浏览器</p>
                  <Space size={0}>
                    <Button
                      type="link"
                      href="https://www.google.cn/intl/zh-CN/chrome/"
                      target="_blank"
                    >
                      <img src={browserIcon1} alt="Google" />
                    </Button>
                    <Button type="link" href="http://www.firefox.com.cn/" target="_blank">
                      <img src={browserIcon2} alt="FireFox" />
                    </Button>
                    <Button type="link" href="https://browser.360.cn/ee/" target="_blank">
                      <img src={browserIcon3} alt="360" />
                    </Button>
                  </Space>
                </Space>
              </Form.Item>
            </Form>
          </TabPane>
        ))}
      </Tabs>
      {/* 弹框 */}
      <CustomPopover
        activeTab={activeKey}
        defaultIsChangePassword={defaultIsChangePassword}
        isModalVisible={isModalVisible}
        setisModalVisible={setisModalVisible}
        callback={defaultIsChangePassword ? () => {
          window.location.href = routerPath;
        } : undefined}
      />
    </div>
  );
});
