import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getUserList,
  userDelete,
  userDetail,
  userReset,
  userSwitch,
  userSave,
  getUnitList,
  unitDelete,
  unitReset,
  unitSwitch,
  getAdminList,
  adminDelete,
  adminDetail,
  adminReset,
  adminSwitch,
  adminAdd,
  adminSave,
} from '@/api/admin/systemManagmentUser';
import { message } from 'antd';

/**
 * 后台-系统管理-用户管理
 */
class AdminSystemManagerUserStore {
  /* ----个人用户s---- */

  // 个人用户当前分页
  pPageNum: number = 1;

  // 个人用户当前每页展示数
  pPageSize: number = 10;

  // 个人用户当前数据总数
  pTotal: number = 0;

  // 个人用户当前搜索关键字
  pKeyWord: string = '';

  // 个人用户当前表格loading
  pLoading: boolean = false;

  // 个人用户当前数据
  pDataList: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 个人用户获取信息列表
   */
  @action getUserList = async (data: any = {}) => {
    const defaultData = {
      keyword: this.pKeyWord,
      page: this.pPageNum,
      limit: this.pPageSize,
    };
    this.pLoading = true;
    const res: any = await getUserList({ ...defaultData, ...data });
    if (res.state.value === 0) {
      runInAction(() => {
        this.pDataList = res.result.data;
        this.pTotal = res.result.total;
        this.pPageNum = res.result.page;
        this.pLoading = false;
        if (data.keyword !== undefined) {
          this.pKeyWord = data.keyword;
        }
      });
    } else {
      message.error('数据获取失败');
    }
  }

  /**
   * 个人用户添加/编辑
   */
  @action userSave = async (data: any = {}) => {
    const res: any = await userSave(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 个人用户重置密码
   */
  @action userReset = async (data: any) => {
    const res: any = await userReset(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 个人用户删除
   */
  @action userDelete = async (data: any) => {
    const res: any = await userDelete(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 个人用户详情
   */
  @action userDetail = async (data: any) => {
    const res: any = await userDetail(data);
    if (res.status === 0) {
      return res.data;
    }
    message.error(res.msg);
    return false;
  }

  /**
   * 个人用户账号启用
   */
  @action userSwitch = async (data: any) => {
    const res: any = await userSwitch(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /* ----个人用户e---- */

  /* ----单位用户s---- */

  // 单位用户当前分页
  uPageNum: number = 1;

  // 单位用户当前每页展示数
  uPageSize: number = 10;

  // 单位用户当前数据总数
  uTotal: number = 0;

  // 单位用户当前搜索关键字
  uKeyWord: string = '';

  // 单位用户当前表格loading
  uLoading: boolean = false;

  // 单位用户当前数据
  uDataList: any[] = [];

  // 单位用户当前账号状态
  uState: string|undefined = undefined;

  // 单位用户当前开始时间
  uStartDate: string = '';

  // 单位用户当前结束时间
  uEndDate: string = '';

  // 单位用户地区搜索
  uArea: string[] = [];

  /**
   * 单位用户获取信息列表
   */
  @action getUnitList = async (data: any = {}) => {
    const defaultData = {
      keyword: this.uKeyWord,
      isForbidden: this.uState,
      beginDate: this.uStartDate,
      endDate: this.uEndDate,
      limit: this.uPageSize,
      page: this.uPageNum,
      areaId: this.uArea[this.uArea.length - 1],
    };
    this.uLoading = true;
    const postData = { ...defaultData, ...data };
    if (data.area) {
      postData.areaId = data.area[data.area.length - 1];
    }
    delete postData.area;
    const res: any = await getUnitList(postData);
    if (res.state.value === 0) {
      runInAction(() => {
        this.uDataList = res.result.data;
        this.uTotal = res.result.total;
        this.uPageNum = res.result.page;
        this.uLoading = false;
        if (data.keyword !== undefined) {
          this.uKeyWord = data.keyword !== '' ? data.keyword : undefined;
        }
        if (data.isForbidden !== undefined) {
          this.uState = data.isForbidden !== '' ? data.isForbidden : undefined;
        }
        if (data.beginDate !== undefined) {
          this.uStartDate = data.beginDate;
        }
        if (data.endDate !== undefined) {
          this.uEndDate = data.endDate;
        }
        if (data.area) {
          this.uArea = data.area;
        }
      });
    } else {
      message.error('数据获取失败');
    }
  }

  /**
   * 单位用户重置密码
   */
  @action unitReset = async (data: any) => {
    const res: any = await unitReset(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 单位用户删除
   */
  @action unitDelete = async (data: any) => {
    const res: any = await unitDelete(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 单位用户账号启用
   */
  @action unitSwitch = async (data: any) => {
    const res: any = await unitSwitch(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /* ----单位用户e---- */

  /* ----后台用户s---- */

  // 后台用户当前分页
  aPageNum: number = 1;

  // 后台用户当前每页展示数
  aPageSize: number = 10;

  // 后台用户当前数据总数
  aTotal: number = 0;

  // 后台用户当前搜索关键字
  aKeyWord: string = '';

  // 后台用户当前表格loading
  aLoading: boolean = false;

  // 后台用户当前数据
  aDataList: any[] = [];

  /**
   * 后台用户获取信息列表
   */
  @action getAdminList = async (data: any = {}) => {
    const defaultData = {
      keyword: this.aKeyWord,
      page: this.aPageNum,
      limit: this.aPageSize,
    };
    this.aLoading = true;
    const res: any = await getAdminList({ ...defaultData, ...data });
    if (res.state.value === 0) {
      runInAction(() => {
        this.aDataList = res.result.data;
        this.aTotal = res.result.total;
        this.aPageNum = res.result.page;
        this.aLoading = false;
        if (data.keyword !== undefined) {
          this.aKeyWord = data.keyword;
        }
      });
    } else {
      message.error('数据获取失败');
    }
  }

  /**
   * 后台用户重置密码
   */
  @action adminReset = async (data: any) => {
    const res: any = await adminReset(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 后台用户删除
   */
  @action adminDelete = async (data: any) => {
    const res: any = await adminDelete(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 后台用户新增
   */
  @action adminAdd = async (data: any) => {
    const res: any = await adminAdd(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 后台用户编辑
   */
  @action adminSave = async (data: any) => {
    const res: any = await adminSave(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 后台用户详情
   */
  @action adminDetail = async (data: any) => {
    const res: any = await adminDetail(data);
    if (res.state.value === 0) {
      return res.result;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 后台用户账号启用
   */
  @action adminSwitch = async (data: any) => {
    const res: any = await adminSwitch(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /* ----后台用户e----  */
}

export default new AdminSystemManagerUserStore();
